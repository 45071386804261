<template>
	<section class="section-wrapper">
		<base-container>
			<base-row>
				<base-col col="12">
					<cms-text
						:value="`${prefix}.section_title`"
						:props="{variant: 'section-header'}"
					/>
				</base-col>
			</base-row>
		</base-container>
		<animation-element
			v-for="(item, index) in value.animation_section_group"
			:key="item.title"
			:item="item"
			:prefix="prefix"
			:index="index"
		/>
	</section>
</template>
<script>
import AnimationElement from './AnimationElement.vue'
export default {
	props: {
		prefix: String,
		value: Object
	},
	components: {
		AnimationElement
	}
}
</script>
