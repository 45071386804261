<template>
	<div class="animation-element" ref="animationElement">
		<div class="sticky">
			<base-container>
				<base-row :reverse="true">
					<base-col col="12" xl="6" offset-xxl="0">
						<image-wrapper class="img-wrapper" wider>
							<cms-image
								:value="`${prefix}.animation_section_group.${index}.image`"
								lazy
								:base="{width: 318}"
								:md="{width: 690, height: 200}"
								:lg="{width: 930, height: 300}"
								:xl="{width: 690}"
							/>
						</image-wrapper>
					</base-col>
					<base-col col="12" xl="5" >
						<div class="content">
							<cms-editor-text
								:value="`${prefix}.animation_section_group.${index}.title`"
								:props="{variant: 'medium-header'}"
							/>
							<cms-editor-text
								:value="`${prefix}.animation_section_group.${index}.content`"
								:props="{variant: 'paragraph', tag: 'div'}"
							/>
						</div>
					</base-col>
				</base-row>
			</base-container>
		</div>
	</div>
</template>
<script>
import ImageWrapper from '~/website/front/components/atoms/ImageWrapper'

export default {
	props: {
		prefix: String,
		item: Object,
		index: Number
	},
	components: {
		ImageWrapper
	},
	mounted () {
		const options = {
			root: null,
			rootMargin: '0px 0px -50px 0px',
			threshold: 0
		}
		const observer = new IntersectionObserver(this.handleIntersection, options)
		observer.observe(this.$refs.animationElement)
	},
	methods: {
		handleIntersection (entries, observer) {
			entries.forEach(entry => {
				entry.target.classList.add('active')
				if (entry.intersectionRatio <= 0) entry.target.classList.remove('active')
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.animation-element {
	opacity: 0;
	margin-bottom: 4rem;
	@include transition;
	@include media-breakpoint-up(lg) {
		min-height: 60vh;
		margin-bottom: 0;
	}
	@include media-breakpoint-up(xl) {
		min-height: 90vh;
	}
	@include media-breakpoint-up(xxl) {
		min-height: 120vh;
	}
	@media (min-width: 2000px) {
		min-height: 60vh;
	}
	&.active {
		opacity: 1;
	}
	.sticky {
		position: sticky;
		margin-bottom: 3rem;
		top: 15%;
		@include media-breakpoint-up(xl) {
			top: 30%;
		}
		& :deep(.content-wrapper) {
			margin-bottom: 0;
		}
		.content {
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
			margin-bottom: 3rem;
		}
	}
}
</style>
